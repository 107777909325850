import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/1080p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "1080p CGI Commands",
  "path": "/1080p_Series_CGI_List/Features_Menu/IR_Nightvision/",
  "dateChanged": "2018-03-23",
  "author": "Mike Polinowski",
  "excerpt": "Camera API control for developers",
  "image": "../../AU_SearchThumb_CGICommands_1080p.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_1080p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "1080p CGI Commands"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='1080p CGI Commands' dateChanged='2018-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Camera API control for developers' image='/images/Search/AU_SearchThumb_CGICommands_1080p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/1080p_Serie_CGI_Befehle/Features_Menu/IR_Nachtsicht/' locationFR='/fr/1080p_Series_CGI_List/Features_Menu/IR_Nightvision/' crumbLabel="1080p CGI Commands" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "the-features-menu--ir-nightvision",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-features-menu--ir-nightvision",
        "aria-label": "the features menu  ir nightvision permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Features Menu :: IR Nightvision`}</h1>
    <hr></hr>
    <EuiSpacer mdxType="EuiSpacer" />
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-features-menu--ir-nightvision"
        }}>{`The Features Menu :: IR Nightvision`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetinfrared"
            }}>{`param.cgi?cmd=getinfrared`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdget_instar_admin-index2"
            }}>{`param.cgi?cmd=get_instar_admin&-index=2`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetplancgi"
            }}>{`param.cgi?cmd=getplancgi`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "paramcgicmdgetinfrared",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetinfrared",
        "aria-label": "paramcgicmdgetinfrared permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getinfrared`}</h2>
    <h3 {...{
      "id": "get-ir-led-parameter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-ir-led-parameter",
        "aria-label": "get ir led parameter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get IR LED Parameter`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`infraredstat`}</code>{`: IR LED Status - auto (controlled by light sensor), close (deactivated)`}</li>
    </ul>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span>{`http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`getinfrared`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span>{`
GET: `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span>{`http://192.168.2.168/param.cgi?cmd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`getinfrared`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-usr`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`admin`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-pwd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`instar`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span>{`
SET: `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span>{`http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`setinfrared`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-infraredstat`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`auto`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdget_instar_admin-index2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdget_instar_admin-index2",
        "aria-label": "paramcgicmdget_instar_admin index2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=get_instar_admin&-index=2`}</h2>
    <h3 {...{
      "id": "get-day--night-mode-parameter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-day--night-mode-parameter",
        "aria-label": "get day  night mode parameter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get Day / Night Mode Parameter`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`value`}</code>{`: Day/Night Mode - auto (controlled by light sensor), open (always day / colour mode), close (always night / black&white mode)`}</li>
    </ul>
    <h3 {...{
      "id": "example-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-1",
        "aria-label": "example 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span>{`http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`get_instar_admin`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-index`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token number"
            }}>{`2`}</span><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span>{`
GET: `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span>{`http://192.168.2.168/param.cgi?cmd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`get_instar_admin`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-index`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token number"
            }}>{`2`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-usr`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`admin`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-pwd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`instar`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span>{`
SET: `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span>{`http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`set_instar_admin`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-index`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token number"
            }}>{`2`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`&`}</span>{`-value`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{`auto`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`\``}</span></span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`This setting only affects the button displayed inside the WebUI menu. To switch your camera into a permanent night or day mode you have to adjust the thresholds for this switching behaviour. This can be done with the following commands:`}</p>
    </blockquote>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`For all camera models - `}<strong parentName="p">{`except`}</strong>{` IN-9020 FHD and IN-9010 FHD:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`set_instar_admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`close`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setircutattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-saradc_switch_value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-saradc_b2c_switch_value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`

param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`set_instar_admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`open`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setircutattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-saradc_switch_value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`270`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-saradc_b2c_switch_value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`270`}</span>{`

param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`set_instar_admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`auto`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setircutattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-saradc_switch_value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`208`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-saradc_b2c_switch_value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`190`}</span></code></pre></div>
    <p><strong parentName="p">{`ONLY`}</strong>{` for the camera models IN-9020 FHD and IN-9010 FHD:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`set_instar_admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`close`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setircutattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-saradc_switch_value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-saradc_b2c_switch_value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`

param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`set_instar_admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`open`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setircutattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-saradc_switch_value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`270`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-saradc_b2c_switch_value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`270`}</span>{`

param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`set_instar_admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-index`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`auto`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setircutattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-saradc_switch_value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`60`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-saradc_b2c_switch_value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`40`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetplancgi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetplancgi",
        "aria-label": "paramcgicmdgetplancgi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getplancgi`}</h2>
    <h3 {...{
      "id": "define-a-time-window-to-switch-ir-leds-from-off-to-auto",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#define-a-time-window-to-switch-ir-leds-from-off-to-auto",
        "aria-label": "define a time window to switch ir leds from off to auto permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Define a Time Window to Switch IR LEDs from off to auto`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`plancgi_enable_[0, 1]`}</code>{`: De/Activate Switch Event at Time - `}{`[0 - 86399]`}{` in seconds = 0:00:00 - 23:59:59 o´clock`}</li>
    </ul>
    <h3 {...{
      "id": "example-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-2",
        "aria-label": "example 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getplancgi
GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getplancgi`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setplancgi`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-plancgi_enable_0`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-plancgi_enable_1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-plancgi_time_0`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`21600`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-plancgi_time_1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`64800`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      